import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: false
})
export class DialogComponent {

  @Input() title;
  @Input() resizable = false;
  @Input() cancelButtonText;
  @Input() confirmButtonText;
  @Input() confirmActionLoadingTooltip;
  @Input() isConfirmButtonEnabled = true;
  @Input() confirmActionLoading = false;
  @Input() confirmButtonColor: ThemePalette = 'primary';

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  private isLoadingInternal = false;

  public cancelClicked(): void {
    this.cancel.emit();
  }

  public confirmClicked(): void {
    this.confirm.emit();
  }

  public get isLoading() {
    return this.isLoadingInternal;
  }

  public async showAsyncProgressForFunction<T>(process: () => Promise<T>): Promise<T> {
    try {
      this.isLoadingInternal = true;
      this.cdr.detectChanges();
      return await process();
    } catch (err) {
      throw err;
    } finally {
      this.isLoadingInternal = false;
    }
  }
}
