<a *ngIf="!linkData.children; else expandedNavLink"
   class="link"
   mat-list-item
   routerLink="{{linkData.link}}"
   routerLinkActive="selected-route">
   <div fxLayout="row" class="link-text">
    <mat-icon class="icon">{{linkData.matIcon}}</mat-icon>
    <span>{{linkData.displayName}}</span>
  </div>
</a>

<ng-template #expandedNavLink>
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="linkData.children | containsActiveLink">
      <mat-expansion-panel-header [expandedHeight]="'48px'">
        <mat-panel-title>
          <mat-icon class="icon">{{linkData.matIcon}}</mat-icon>
          <div fxFlex="column" fxFlexAlign="center">{{linkData.displayName}}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a *ngFor="let childNavLink of linkData.children; trackByProperty: 'childLinks'"
           class="link"
           mat-list-item
           routerLink="{{childNavLink.link}}"
           routerLinkActive="selected-route">
           <div class="link-text">
            {{childNavLink.displayName}}
          </div>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
