import { Component } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { OfficeHoursService } from '@shared/services/office-hours/office-hours.service';

interface OnInit {}

@Component({
    selector: 'app-office-hours',
    templateUrl: './office-hours.component.html',
    styleUrls: ['./office-hours.component.scss'],
    standalone: false
})
export class OfficeHoursComponent implements OnInit {
  constructor(
    public mediaObserver: MediaObserver,
    public officeHoursService: OfficeHoursService,
  ) {}

  ngOnInit() {
    this.officeHoursService.initialiseSchedule();
  }
}
