import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { firstValueFrom } from 'rxjs';

import { IAMSOpenIDService } from '@backend-client/services/iamsopen-id.service';
import { TubOpenidProvider } from '@backend-client/models/tub-openid-provider';
import { AuthenticationService } from '@authentication/authentication.service';
import { ServerStatusService } from '@shared/services/server-status.service';
import { CustomErrorStateMatcher } from '@app/modules/shared/utils/custom-error-state-matcher';
import { LoginEmailPasswordService } from './login-email-password.service';
import { ServerStatus }from '@shared/services/server-status.service';

@Component({
    selector: 'app-login-email-password',
    templateUrl: './login-email-password.component.html',
    styleUrls: ['./login-email-password.component.scss'],
    standalone: false
})
export class LoginEmailPasswordComponent implements OnInit {
  @Output() credentials = new EventEmitter<{ email: string, password: string }>();
  @Output() ssoLoggedIn = new EventEmitter();
  @Output() hasForgotPassword = new EventEmitter();
  public loginFormGroup: UntypedFormGroup;
  public serverStatusMessage: string;
  public isPasswordAutofilled = false;
  public loggedInEmail: string = null;
  public errorMatcher = new CustomErrorStateMatcher();
  public providers: TubOpenidProvider[] = []; // For storing the OpenID providers

  constructor(
    private formBuilder: UntypedFormBuilder,
    private serverStatus: ServerStatusService,
    private angularFireAuth: AngularFireAuth,
    public authenticationService: AuthenticationService,
    private iamsService: IAMSOpenIDService,
    private loginEmailPasswordService: LoginEmailPasswordService,
  ) {}

  get isServerOnline(): boolean {
    return this.serverStatus.isOnline;
  }

  async ngOnInit() {
    this.loggedInEmail = this.authenticationService.loggedInEmail.getValue();
    this.loginFormGroup = this.formBuilder.group({
      emailFormControl: [ this.loggedInEmail || '', [ Validators.required, Validators.email ] ],
      passwordFormControl: [ '', [ Validators.required ] ]
    });

    if (this.loggedInEmail) {
      this.loginFormGroup.controls.emailFormControl.disable();
    }

    this.serverStatus.isServerOnline.subscribe((status: ServerStatus) => {
      this.serverStatusMessage = this.generateServerStatusMessage({ offlineTime: status.offlineTime });
    });

    // Initialize the list of OpenID providers
    await this.loginEmailPasswordService.getProviders().then((providers: TubOpenidProvider[]) => { this.providers = providers; });
  }

  public setAutoFill(): void {
    this.isPasswordAutofilled = true;
  }

  public resetForm(): void {
    this.loginFormGroup.reset({
      emailFormControl: this.loggedInEmail || ''
    });
    this.isPasswordAutofilled = false;
  }

  public async notYouClicked(): Promise<void> {
    await this.angularFireAuth.signOut();
    location.href = '/home';
  }

  public submitButtonClicked(email: string, password: string): void {
    this.credentials.emit({ email, password });
  }

  public async handleOpenIDLogin(provider: string) {
    const loginUrl = await firstValueFrom(this.iamsService.IamsOpenIDGetLoginURL(provider));
    const nonce = this.loginEmailPasswordService.generateRandomNonceState();
    const nonceState = {
       [nonce] : {
        redirectUrl: window.location.origin + '/callback',
        expiresOn: this.loginEmailPasswordService.EXPIRY_TIME
      }
    };
    localStorage.setItem('dbsso', JSON.stringify(nonceState));

    // Open the authorization URL and append state of dashboard so Thrive Auth knows where to return the request to
    window.open(`${loginUrl}&state=${JSON.stringify(nonceState)}` , '_self');
  };

  private generateServerStatusMessage(status: { offlineTime: number } ): string {
    if (this.serverStatus.isGoingOffline) {
      const offlineTime = new Date(status.offlineTime);
      return 'The Thrive server will go offline at ' + offlineTime;
    } else if (!this.serverStatus.isOnline) {
      return 'The Thrive server is currently offline for maintenance';
    }
    return '';
  }
}
