import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ErrorHandler,
  forwardRef,
  Injector,
  isDevMode,
  NgModule,
  RendererFactory2,
  inject,
  provideAppInitializer,
  APP_ID
} from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { REGION } from '@angular/fire/compat/functions';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireRemoteConfig, AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from '@backend-client/api.module';
import { ApiInterceptor } from '@backend-client/api-interceptor';
import { AuthenticationModule } from '@authentication/authentication.module';
import { DashboardErrorHandler } from '@classes/dashboard-error-handler';
import { InjectableEnvironmentService } from '@shared/services/injectable-environment-service';
import { EnvironmentService } from '@shared/services/environment.service';
import { FeatureFlagService } from '@shared/services/feature-flag.service';
import { GoCoreService } from '@shared/services/gocore/gocore.service';
import { ThemeService } from '@shared/services/theme.service';
import { AuthenticationService } from './modules/authentication/authentication.service';

// AoT requires an exported function for factories
// Method of loading localisation data
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Wait until localisation data has loaded before we render the page
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
        console.log(`Successfully initialized '${langToSet}' language.'`);
      }, () => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

let isGoCoreInitialised = false;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    // TODO: this should pivot to target thrive-unified-backend.appspot.com when deploying/testing against live
    ApiModule.forRoot(environment.thriveUnifiedBackend),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthenticationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Polling for server status prevents the app stabilising, so the service worker never gets registered
      // The below setting forces the service worker to register immediately https://angular.io/api/service-worker/SwRegistrationOptions
      registrationStrategy: 'registerImmediately'
    }),
  ],
  providers: [
    AngularFireAuthGuard,
    ApiInterceptor,
    {
      provide: APP_ID,
      useValue: 'serverApp'
    },
    { provide: APP_ID,  useValue: 'serverApp' },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: forwardRef(() => ApiInterceptor),
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: REGION,
      useValue: 'europe-west1'
    },
    {
      provide: ErrorHandler,
      useClass: DashboardErrorHandler
    },
    provideAppInitializer(() => {
        const initializerFn = (appInitializerFactory)(inject(TranslateService), inject(Injector));
        return initializerFn();
      }),
    {
      provide: InjectableEnvironmentService,
      useClass: EnvironmentService
    },
    // Firebase Remote Config Default Settings
    {
      provide: DEFAULTS, useValue: {}
    },
    {
      provide: SETTINGS, useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    },
    ThemeService,
    provideAppInitializer(() => {
        const initializerFn = ((environmentService: EnvironmentService, themeService: ThemeService, renderer: RendererFactory2) => async () => {
        const theme = environmentService.getEnvironment().theme ?? 'default-theme';
        themeService.setTheme(theme, renderer.createRenderer(null, null));
      })(inject(EnvironmentService), inject(ThemeService), inject(RendererFactory2));
        return initializerFn();
      }),
    FeatureFlagService,
    provideAppInitializer(() => {
        const initializerFn = ((featureFlagService: FeatureFlagService) => async () => { return featureFlagService.initialise(); })(inject(FeatureFlagService));
        return initializerFn();
      }),
    GoCoreService,
    provideAppInitializer(() => {
        const initializerFn = ((gocoreService: GoCoreService, authenticationService: AuthenticationService) => async () => {
        authenticationService.getUserScopes$().subscribe(scopes => {
          // Determines if a user is a therapist by querying the scopes and checking if they have a scope that all therapists should have.
          // At this point we are only using GoCore for Chat, so users who have the scope 'chat:view-active-chats' should be the only
          // ones relying on GoCore.
          const isUserATherapist = scopes.some(val => val === 'chat:view-active-chats');
          if (isUserATherapist && !isGoCoreInitialised) {
            isGoCoreInitialised = true;
            gocoreService.initialise();
          }
        });
      })(inject(GoCoreService), inject(AuthenticationService));
        return initializerFn();
      })
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
 constructor(private remoteConfig: AngularFireRemoteConfig) {
  this.remoteConfig.fetchAndActivate();
 }
}

