import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ConvertDateToTimePipe } from './pipes/convert-date-to-time.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import { ConvertStringToTitleCasePipe } from './pipes/convert-string-to-title-case.pipe';
import { AsyncButtonComponent } from './components/async-button/async-button.component';
import { PanelSpinnerComponent } from './components/panel-spinner/panel-spinner.component';
import { DisplayUserNamePipe } from './pipes/display-user-name.pipe';
import { TimeAgoExtendedPipe } from './pipes/time-ago-extended.pipe';
import { ConvertUnixTimeToLocaleStringPipe } from './pipes/convert-unix-time-to-locale-string.pipe';
import { ConvertUnixTimeToDateStringPipe } from './pipes/convert-unix-time-to-date-string.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { LfsFilePathPipe } from './pipes/lfs-file-path.pipe';
import { ConvertUnixTimeToUtcStringPipe } from './pipes/convert-unix-time-to-utc-string.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { GetDayOfWeekPipe } from './pipes/get-day-of-week.pipe';
import { HashStringPipe } from './pipes/hash-string.pipe';
import { HelpIconComponent } from './components/help-icon/help-icon.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DiallingPrefixPipe } from './services/phone/dialling-prefix.pipe';
import { CountryNamePipe } from './services/phone/country-name.pipe';
import { EnumKeysPipe } from './pipes/enum-keys.pipe';
import { InvokeFunctionWithContextPipe } from './pipes/invoke-function-with-context.pipe';
import { InvokeFunctionPipe } from './pipes/invoke-function.pipe';
import { HideDirective } from './directives/hide.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule, TimeAgoPipe } from 'ngx-pipes';
import { ConvertTimeToStringPipe } from './pipes/convert-time-to-string.pipe';
import { ConvertCamelCaseToTitleCasePipe } from './pipes/convert-camel-case-to-title-case.pipe';
import { UserHasScopeDirective } from '../authentication/user-has-scope.directive';
// go-chat-duplication start
import { GoAvatarComponent } from '@shared/components/go-avatar/go-avatar.component';
import { NgForTrackByPropertyModule } from './modules/track-by-property.module';
// go-chat-duplication end

@NgModule({
  declarations: [
    AvatarComponent,
    DialogComponent,
    ConvertDateToTimePipe,
    ConvertStringToTitleCasePipe,
    ConvertCamelCaseToTitleCasePipe,
    AsyncButtonComponent,
    PanelSpinnerComponent,
    DisplayUserNamePipe,
    TimeAgoExtendedPipe,
    ConvertUnixTimeToDateStringPipe,
    EnumToArrayPipe,
    SanitizePipe,
    LfsFilePathPipe,
    ConvertUnixTimeToLocaleStringPipe,
    ConvertUnixTimeToDateStringPipe,
    ConvertUnixTimeToUtcStringPipe,
    TruncatePipe,
    GetDayOfWeekPipe,
    HashStringPipe,
    HelpIconComponent,
    FileSizePipe,
    DiallingPrefixPipe,
    CountryNamePipe,
    EnumKeysPipe,
    InvokeFunctionWithContextPipe,
    InvokeFunctionPipe,
    HideDirective,
    ConvertTimeToStringPipe,
    UserHasScopeDirective,
    GoAvatarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    ClipboardModule,
    TranslateModule,
    NgPipesModule,
    NgForTrackByPropertyModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    AvatarComponent,
    ConvertDateToTimePipe,
    DialogComponent,
    ConvertStringToTitleCasePipe,
    ConvertCamelCaseToTitleCasePipe,
    AsyncButtonComponent,
    PanelSpinnerComponent,
    DisplayUserNamePipe,
    TimeAgoExtendedPipe,
    ConvertUnixTimeToDateStringPipe,
    EnumToArrayPipe,
    SanitizePipe,
    LfsFilePathPipe,
    ConvertUnixTimeToLocaleStringPipe,
    ConvertUnixTimeToDateStringPipe,
    ClipboardModule,
    ConvertUnixTimeToUtcStringPipe,
    TruncatePipe,
    GetDayOfWeekPipe,
    HelpIconComponent,
    FileSizePipe,
    DiallingPrefixPipe,
    CountryNamePipe,
    EnumKeysPipe,
    InvokeFunctionWithContextPipe,
    InvokeFunctionPipe,
    HideDirective,
    ConvertTimeToStringPipe,
    UserHasScopeDirective,
    GoAvatarComponent,
    NgForTrackByPropertyModule
  ],
  providers: [ConvertStringToTitleCasePipe, DisplayUserNamePipe, ConvertUnixTimeToUtcStringPipe, TimeAgoPipe],
})
export class SharedModule {}
