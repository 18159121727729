<button mat-raised-button color="warn"
        [matMenuTriggerFor]="failedMessageNotificationMenu"
        *ngIf="failedMessages?.length > 0">
  Failed Messages <mat-icon iconPositionEnd>sms_failed</mat-icon>
</button>
<mat-menu #failedMessageNotificationMenu="matMenu">
  <ng-container *ngFor="let failedMessage of failedMessages; trackByProperty: 'failedToSendMessages'">
    <a routerLink="/therapist/chat/{{failedMessage.chatSessionId}}"
      mat-menu-item
      fxLayout="column"
      fxLayoutAlign="center">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon color="warn">chat</mat-icon>
          <div>
            {{failedMessage?.chatSession?.patient?.email | displayUserName: failedMessage?.chatSession?.patient?.name}}
          </div>
        </div>
        <div id="notification-message">
          {{failedMessage.messageText | truncate: 200 }}
        </div>
      </div>
      <div id="timestamp" fxFlexAlign="end">
        {{failedMessage.timestamp | getDayOfWeek}} {{failedMessage.timestamp | convertDateToTime}}
      </div>
    </a>

  </ng-container>
  <div
    fxLayout="row"
    fxLayoutAlign="center">
    <button mat-raised-button color="accent" (click)="retryAllFailedMessages()">Retry All Failed Messages <mat-icon iconPositionEnd>replay</mat-icon></button>
  </div>
</mat-menu>
