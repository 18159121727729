import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Observable} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TotpService } from '@authentication/totp.service';
import base32Encode from 'base32-encode';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrls: ['./qr-code.component.scss'],
    standalone: false
})
export class QrCodeComponent implements OnInit {

  @Input() email: string;
  @Output() mfaSetupSubmitted: EventEmitter<{token: string, secret: string}> = new EventEmitter();
  @Output() back: EventEmitter<null> = new EventEmitter();

  public readonly QR_CODE_LENGTH = 6;

  public secret: string;
  public oneTimePasswordFormGroup: UntypedFormGroup;
  public googleAuthLink = '';

  public qrData: Observable<string | null> = this.angularFireAuth.user.pipe(switchMap(user => {
    if (user) {
      return this.totpService.generateUrl(user.email, this.secret);
    } else {
      return Promise.resolve(null);
    }
  }));

  constructor(private formBuilder: UntypedFormBuilder,
              public angularFireAuth: AngularFireAuth,
              private totpService: TotpService) { }

  private generateSecret(): void {
    const array = new Uint8Array(20);
    window.crypto.getRandomValues(array);
    this.secret = base32Encode(array, 'RFC4648');
  }

  ngOnInit() {
    this.generateSecret();
    this.oneTimePasswordFormGroup = this.formBuilder.group({
      oneTimePasswordFormControl: [ '' ]
    });
  }

  public submitMfaSetup(token: string): void {
    this.mfaSetupSubmitted.emit({ token, secret: this.secret });
  }

  public backButtonClicked(): void {
    this.oneTimePasswordFormGroup.reset();
    this.back.emit();
  }
}
