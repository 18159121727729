import { Component, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { ActiveChatSessionsService } from '@app/modules/therapist/chat/active-chat-sessions.service';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { OutgoingMessage } from '@app/modules/therapist/chat/classes/outgoing-message';
import { TubChatSessionV2 } from '@backend-client/models';
import { OutgoingChatMessageService } from '@app/modules/therapist/chat/outgoing-chat-message.service';

@Component({
    selector: 'app-failed-messages-button',
    templateUrl: './failed-messages-button.component.html',
    styleUrls: ['./failed-messages-button.component.scss'],
    standalone: false
})
export class FailedMessagesButtonComponent extends OnDestroyObservable implements OnInit {
  constructor(
    private activeChatSessionsService: ActiveChatSessionsService,
    private outgoingChatMessageService: OutgoingChatMessageService) { super(); }

  public failedMessages: (OutgoingMessage & { chatSession: TubChatSessionV2, timestamp: Date })[];

  ngOnInit() {
    this.outgoingChatMessageService.getFailedMessages$()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((failedMessages) => {
        const activeChatSessions = this.activeChatSessionsService.chatSessions$.getValue();

        // Add chatSession and timestamp properties to each failed message
        this.failedMessages = failedMessages.map(failedMessage => ({
          ...failedMessage,
          chatSession: activeChatSessions.find(chatSession => chatSession.id === failedMessage.chatSessionId),
          timestamp: new Date(failedMessage.clientTime)
        }));
      });
  }

  retryAllFailedMessages() {
    for (const failedMessage of this.failedMessages) {
      this.outgoingChatMessageService.retryMessage(failedMessage.chatMessageKey);
    }
  }
}
