// go-chat-duplication
import { Directive, Host, Input, OnInit } from '@angular/core';

import { GoDatabaseNotificationType } from '@shared/services/go-notifications';
import { GoPrimaryNotificationComponent } from '../go-primary-notification/go-primary-notification.component';
import { GoDashboardNotification } from './go-dashboard-notification';

@Directive({
    selector: '[appGoAssignedPatientNotification]',
    standalone: false
})
export class GoAssignedPatientNotificationDirective implements OnInit {

  @Input() notificationData: GoDatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: GoPrimaryNotificationComponent) { }

  ngOnInit() {
    const { patientId, fromTherapistFirstName, fromTherapistLastName } = this.notificationData.data;

    const therapistName = `${fromTherapistFirstName} ${fromTherapistLastName}`;
    const timestamp = this.notificationData.ts;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    const icon = 'folder_shared';
    const link = `/therapist/assigned-patients/patient-profile/${patientId}`;
    const title = 'Patient Record Shared';
    const message = `A patient record has been shared with you by ${therapistName}.`;

    this.primaryNotificationComponent.notification = new GoDashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }

}
