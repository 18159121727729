<!-- go-chat-duplication -->
<button *ngIf="(totalChatNotifications$ | async) >= 0; else loadingSpinner" mat-icon-button
        [matMenuTriggerFor]="messageNotificationMenu">
  <mat-icon
    [matBadgeHidden]="(totalChatNotifications$ | async) === 0"
    [matBadge]="(totalChatNotifications$ | async).toString()"
    matBadgeColor="warn">
    email
  </mat-icon>
</button>
<mat-menu #messageNotificationMenu="matMenu">
  <div *ngIf="GoCoreChatService.isLoadingActiveChannel else loaded">
    <div class="notification-text" mat-menu-item>Loading...</div>
  </div>
  <ng-template #loaded>
    <a *ngIf="(totalChatNotifications$ | async) < 1" disabled mat-menu-item>
      You have no new messages
    </a>
    <ng-container *ngFor="let chatSession of (chatSessions$ | async); trackByProperty: 'goMsgNotifications'">
      <a *ngIf="chatSession.unreadMessageCount > 0" routerLink="/therapist/go-chat/{{chatSession.id}}"
         mat-menu-item>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <app-go-avatar
            [matBadge]="chatSession.unreadMessageCount.toString()"
            matBadgeColor="warn"
            matBadgePosition="before"
            [patientId]="chatSession.patient.id"
            diameter="25px">
          </app-go-avatar>
          <div class="notification-text">{{ chatSession.patient.email | displayUserName: chatSession.patient.name }}</div>
        </div>
      </a>
    </ng-container>
  </ng-template>

</mat-menu>

<ng-template #loadingSpinner>
  <div id="spinner-container" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>
