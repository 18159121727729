import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-async-button',
    templateUrl: './async-button.component.html',
    styleUrls: ['./async-button.component.scss'],
    standalone: false
})
export class AsyncButtonComponent {

  @Input() action: () => Promise<void>;
  @Input() color = '';
  @Input() disabled = false;

  public isLoading = false;

  public async buttonClicked(): Promise<void> {
    this.isLoading = true;
    try {
      await this.action();
    } catch (err) {
      console.error(err);
    }
    this.isLoading = false;
  }

}
