// go-chat-duplication
import { Component, Input, ViewEncapsulation } from '@angular/core';

import { GoDashboardNotification } from '../go-notifications/go-dashboard-notification';
import { GoNotificationStateService } from '@shared/services/go-notifications';

@Component({
    selector: 'app-go-primary-notification',
    templateUrl: './go-primary-notification.component.html',
    styleUrls: ['./go-primary-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GoPrimaryNotificationComponent {

  @Input() notification: GoDashboardNotification;

  constructor(private notificationStateService: GoNotificationStateService) {}

  public async notificationClicked(): Promise<void> {
    if (this.notification.readAtTime == null) {
      await this.notificationStateService.markChatRequestNotificationsAsRead(this.notification.id);
    }
  }

}
