import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageNotificationButtonService } from './message-notification-button.service';
import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { takeUntil } from 'rxjs/operators';
import { Notification } from '@layout/main-nav/message-notification-button/notification';

@Component({
    selector: 'app-message-notification-button',
    templateUrl: './message-notification-button.component.html',
    styleUrls: ['./message-notification-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MessageNotificationButtonComponent extends OnDestroyObservable implements OnInit {
  public notifications: Notification[];

  constructor(public messageNotificationButtonService: MessageNotificationButtonService) {
    super();
  }

  ngOnInit(): void {
    this.messageNotificationButtonService.notifications
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(async notifications => {
        // Set the notifications immediately, so they appear to the user as soon as possible
        this.notifications = notifications;
        // Attempt to retrieve additional data
        await this.populateMissingPatientDetails();
      });
  }

  /**
   * If there is no email address associated with the user, fetch that user's name and email address from the server
   * @private
   */
  private async populateMissingPatientDetails(): Promise<void> {
    for (const notification of this.notifications) {
      // All patients should have an email address - if they do not, then we do not have any of their details
      if (!notification.patient.email) {
        const patientDetails = await this.messageNotificationButtonService.getPatientDetails(
          notification.chatSessionId,
        );
        notification.patient.email = patientDetails.email;
        notification.patient.name = patientDetails.name;
      }
    }
  }
}
