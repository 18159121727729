import { Directive, Host, Input, OnInit } from '@angular/core';

import { PrimaryNotificationComponent } from '../primary-notification/primary-notification.component';
import { DatabaseNotificationType } from '@shared/services/notifications';
import { DashboardNotification } from './dashboard-notification';

@Directive({
    selector: '[appChatRequestNotification]',
    standalone: false
})
export class ChatRequestNotificationDirective implements OnInit {

  @Input() notificationData: DatabaseNotificationType;

  constructor(@Host() private primaryNotificationComponent: PrimaryNotificationComponent) { }

  ngOnInit() {
    const { fromTherapistFirstName, fromTherapistLastName } = this.notificationData.data;
    const therapistName = `${fromTherapistFirstName} ${fromTherapistLastName}`;

    let icon: string;
    const link = '/therapist/chat-requests';
    let title: string;
    let message: string;
    const timestamp = this.notificationData.ts;
    const readAtTime = this.notificationData.read;
    const id = this.notificationData.id;

    switch (this.notificationData.data.requestType) {
      case 'chat-patient-request': {
        icon = 'chat';
        title = 'New Chat Request';
        message = `You have received a new chat request from a patient.`;
        break;
      }
      case 'chat-therapist-handover': {
        icon = 'next_week';
        title = 'Patient Handover';
        message = `${therapistName} has requested to hand over a patient to you.`;
        break;
      }
      case 'chat-therapist-invite': {
        icon = 'person_add';
        title = 'New Chat Invite';
        message = `${therapistName} has invited you into a chat.`;
      }
    }

    this.primaryNotificationComponent.notification = new DashboardNotification(icon, link, title, message, timestamp, readAtTime, id);
  }
}
