import { Component, OnInit } from '@angular/core';

import { map, take, takeUntil } from 'rxjs/operators';

import { OnDestroyObservable } from '@classes/on-destroy-observable';
import { MessageService } from '@shared/services/message.service';
import {
  DatabaseNotificationType,
  NotificationsService,
  NotificationStateService,
  AudioService
} from '@shared/services/notifications';
import { NotificationState } from '@shared/models/notification-state.model';
import { AssignedPatientsService } from '@app/modules/therapist/assigned-patients/assigned-patients.service';

@Component({
    selector: 'app-primary-notification-button',
    templateUrl: './primary-notification-button.component.html',
    styleUrls: ['./primary-notification-button.component.scss'],
    standalone: false
})
export class PrimaryNotificationButtonComponent extends OnDestroyObservable implements OnInit {

  public notifications: DatabaseNotificationType[] = [];

  constructor(
    private notificationsService: NotificationsService,
    private notificationStateService: NotificationStateService,
    private assignedPatientsService: AssignedPatientsService,
    private audioService: AudioService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    const notificationObservable = this.notificationsService.getNotificationObservable().pipe(takeUntil(this.ngOnDestroy$));

    notificationObservable
    .pipe(
      map(notifications => {
        return notifications
          .filter(notifications => notifications.data['pubNub'] === 'false' || !notifications.data.hasOwnProperty('pubNub') );
      }))
    .subscribe(databaseNotifications => {
      this.notifications = databaseNotifications;

      const unreadAssignedPatients = this.notifications.filter(notification => notification.data.type === 'assignedPatient' && notification.read === null);
      if (unreadAssignedPatients.length > 0) {
        this.assignedPatientsService.dataStateValid$.next(false);
      }

      if (localStorage.getItem('soundNotificationsEnabled') === 'false' ? false : true) {
        const unreadChatRequests = this.notifications.filter(notification => notification.data.type === 'chatRequest' && notification.read === null);

        let alreadyNotified = [];
        this.notificationStateService.notifiedUserRequests.pipe(take(1)).subscribe(n => alreadyNotified = n);

        if (unreadChatRequests.length > 0) {
          let unreadRequestArray = this.unreadRequests(unreadChatRequests);

          if (unreadRequestArray.length > 0 && alreadyNotified.length === 0) {
            this.audioService.playAudioChatRequest();
            unreadRequestArray = this.notificationStateService.setAllUserNotified(unreadRequestArray);
          } else {
            for (const unreadRequest of unreadRequestArray) {
              const notified = alreadyNotified.filter(n => n.id === unreadRequest.id);

              if (notified.length === 0 &&
                unreadRequest.unread === 1 &&
                !unreadRequest.userNotified
              ) {
                this.audioService.playAudioChatRequest();
                unreadRequestArray = this.notificationStateService.setUserNotified(unreadRequest.id, unreadRequestArray);
              }
            }
          }

          this.notificationStateService.notifiedUserRequests.next(unreadRequestArray);
        }
      }
    });
  }

  public get unreadNotificationsCount(): number {
    return this.notifications.filter(notification => notification.read === null).length;
  }

  public async markAllChatRequestNotificationsAsRead(): Promise<void> {
    const unreadNotifications = this.notifications.filter(notification => notification.read === null);
    const unreadNotificationIds = unreadNotifications.map(notification => notification.id);
    try {
      await this.notificationStateService.markAllChatRequestNotificationsAsRead(unreadNotificationIds);
    } catch (err) {
      this.messageService.showMessage('Error: One or more notifications could not be cleared');
    }
  }

  private unreadRequests(unreadChatRequests: DatabaseNotificationType[]): NotificationState[] {
    const addToNotified: Array<NotificationState> = [];

    for (const unreadRequest of unreadChatRequests) {
      const unread = {
        id: unreadRequest.id,
        unread: 1,
        userNotified: false
      } as NotificationState;

      addToNotified.push(unread);
    }

    return addToNotified;
  }
}
