<div id="server-status">{{serverStatusMessage}}</div>
<form [formGroup]="loginFormGroup">
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Username</mat-label>
    <button id="not-you-button" type="button" class="link" (click)="notYouClicked()" [fxHide]="!loggedInEmail" mat-flat-button matSuffix>Not you?</button>

    <input id="e2e-input-1" #email matInput type="email" placeholder="name@domain.com" formControlName="emailFormControl" [errorStateMatcher]="errorMatcher">
    <mat-error id="e2e-error-1" *ngIf="loginFormGroup.controls['emailFormControl'].hasError('email') &&
                    !loginFormGroup.controls['emailFormControl'].hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error id="e2e-error-2" *ngIf="loginFormGroup.controls['emailFormControl'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Password</mat-label>
    <mat-pass-toggle-visibility #togglePassword matSuffix></mat-pass-toggle-visibility>
    <input id="e2e-input-3" #password matInput type="password" placeholder="Password" formControlName="passwordFormControl"
            [type]="togglePassword.type" (cdkAutofill)="setAutoFill()" [errorStateMatcher]="errorMatcher" autocomplete="off">
    <mat-error *ngIf="loginFormGroup.controls['passwordFormControl'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <br>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button type="button" class="link" mat-button (click)="hasForgotPassword.emit(); resetForm()">Forgot password?
    </button>
    <button id="e2e-submit-login" type="submit" mat-raised-button color="primary"
            [disabled]="!isPasswordAutofilled && (!loginFormGroup.valid || !isServerOnline)"
            (click)="submitButtonClicked(email.value, password.value)">
      Login
    </button>
  </div>
</form>

<!-- SSO Providers List -->
<div class="providers-list" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="sso-title">
    <span class="line"></span>
    <p>Or select an SSO provider</p>
  </div>
  <div class="sso-button-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">
    <button *ngFor="let provider of providers; trackByProperty: 'ssoProviders'" mat-raised-button class="sso-provider-button" color="primary" (click)="handleOpenIDLogin(provider.clientID)">
      {{ provider.client_name }}
    </button>
  </div>
</div>
