<div>
  <div id="header" fxLayout="row" fxLayoutAlign="space-between center">
    <span>
      <img id="thrive-logo" src="/assets/images/thrive-logo-with-title.webp" alt="thrive-logo" />
    </span>
  </div>
  <hr />
</div>
<div id="doc-container">
  <div *ngIf="state == legalDocsState.Loading">Loading, please wait</div>
  <div [innerHTML]="legalHtml" *ngIf="state == legalDocsState.Loaded"></div>
  <div *ngIf="state == legalDocsState.Error">{{ errorMessage }}</div>
</div>
